import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBonus } from "../../../interfaces/models/bonus";
import { IBonusState } from "../../../interfaces/store/bonus-interface";
import { IBonusDetails } from "../../../interfaces/store/viewing-bonus-interface";

const initialState: IBonusDetails = {
  bonusDetails: null,
  error: null,
  loading: false,
};

const _getBonusById = (
  state: IBonusDetails,
  payload: PayloadAction<{ bonusId: string | undefined }>
) => {
  state.loading = true;
  state.error = null;
};

const _fetchByIdSuccess = (
  state: IBonusDetails,
  { payload }: PayloadAction<IBonus>
) => {
  state.bonusDetails = payload;
  state.error = null;
  state.loading = false;
};

const _fetchByIdFail = (state: IBonusDetails, { payload }: PayloadAction<{ message: string }>) => {
  state.bonusDetails = null;
  state.error = payload;
  state.loading = false;
};

const _redeemBonus = (state: IBonusDetails, { payload }: PayloadAction<{ id: string }>) => {
  state.error = null;
  state.loading = true;
}

const bonusDetailsSlice = createSlice({
  name: "bonusDetails",
  initialState,
  reducers: {
    fetchByIdSuccess: _fetchByIdSuccess,
    fetchByIdFail: _fetchByIdFail,
    getBonusById: _getBonusById,
  },
});

const { actions, reducer } = bonusDetailsSlice;
export const { fetchByIdSuccess, fetchByIdFail, getBonusById } = actions;

export default reducer;
