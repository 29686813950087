import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { clearBonusArray, loadPage } from "../../store/features/bonus/bonus-slice";
import { InfiniteScroll } from "../infScroll/InfiniteScroll";
import { UserHeader } from "../userHeader/UserHeader";
import Card from "./card/Card";
import { HomeContainer, NotFoundMessage } from "./HomeStyles";

const RESULTS_PER_LOAD_LIMIT = 25;

const Home = () => {
  const auth = useAppSelector((state) => state.auth);
  const bonusState = useAppSelector((state) => state.bonus);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (bonusState.bonusesArray!.length < RESULTS_PER_LOAD_LIMIT) dispatch(loadPage({ page: 0, RESULTS_PER_LOAD_LIMIT }));
  }, []);

  const loadNextPageCallback = () => {
    dispatch(loadPage({ page: bonusState.metadata!.page! + 1, RESULTS_PER_LOAD_LIMIT }));
  };

  return (
    <HomeContainer style={{marginTop: auth.user ? '90px' : '70px'}}>
      <InfiniteScroll loadCallback={loadNextPageCallback} 
                      loading={bonusState.loading} 
                      page={bonusState.metadata.page} 
                      totalItems={bonusState.metadata.totalItems} 
                      limit={RESULTS_PER_LOAD_LIMIT}>
        {bonusState.bonusesArray
          ? bonusState.bonusesArray.map((bonus) => {
              return bonus.available! > 0 ? <Card card={bonus} key={bonus._id} /> : null;
            })
          : null}

        {bonusState.error ? <NotFoundMessage varient="h1">{t("home.error")}</NotFoundMessage> : null}
      </InfiniteScroll>
    </HomeContainer>
  );
};

export default Home;
