import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { INavProfileButtonProps } from "../../interfaces/components/navigation/navigation";
import {
  FlexColumn,
  FlexRowAligned,
  FlexRowSpaced,
} from "../common/containers";
import ProfileRoundImage from "../ui/ProfileRoundImage";

export const TopNavHeightHome = 100;
export const TopNavHeight = 20;
export const TopNavVeticalPadding = 20;
export const TopNavFullHeight = TopNavHeightHome + TopNavVeticalPadding * 2;

const TopNav = styled.nav`
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  padding: ${TopNavVeticalPadding + "px"};
  /* height: ${TopNavHeight + "px"}; */

  align-items: center;
  background: var(--nav-background);
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 4px 1px #9F9F9F; 
  box-shadow: 0px 0px 4px 1px #9F9F9F;
  /* filter: drop-shadow(0px 0px 2px #9F9F9F); */
`;
const TopNavContainer = styled(FlexRowSpaced)`
  width: 100%;
  margin-bottom: 10px;
`;

const NavLogo = styled.img`
  height: 28px;
  padding: 0px 25px;
  white-space: nowrap;
`;

const Container = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  & > * {
    margin: 0.25rem;
  }
  &:focus {
    outline: none;
  }
`;

const SideNav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  min-width: 270px;
  background: white;
`;

const NavProfileButton = (props: INavProfileButtonProps) => {
  return (
    <Container onClick={props.onclick}>
      <ProfileRoundImage
        width={67}
        height={67}
        fontSize={40}
        fullName={props.user.fullName}
        imgUrl={props.user.avatar}
      />
    </Container>
  );
};

const LogoContainer = styled.div`
  z-index: 5;
  display: flex;
  align-items: space-between;
  justify-content: flex-end;
  width: 80px;
  align-items: flex-end;
`;
const StyledMenu = styled.div`
  z-index: 4;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000040;
  overflow: hidden;
`;
const StyledItems = styled(FlexColumn)`
  z-index: 4;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--nav-menu-background);
  text-decoration: none;
`;

const StyledContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 80px 20px;
  height: 60%;
  @media only screen and (max-height: 500px) {
    margin-top: 30px;
  }
`;

const StyledContainerBtm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 0px 20px;
  height: 30%;
  @media only screen and (max-height: 500px) {
    padding-top: 0px;
  }
`;

interface Iprops {
  onClick?: (e: Event) => void;
}

const StyledItem = styled.div<Iprops>`
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    text-decoration: none;
    padding: 1rem;
    color: var(--nav-text);
    cursor: pointer;
  }
`;

const navigation = {
  TopNav,
  StyledMenu,
  StyledItems,
  StyledContainerBtm,
  StyledContainerTop,
  StyledItem,
  NavLogo,
  NavProfileButton,
  SideNav,
  LogoContainer,
  TopNavContainer,
};

export default navigation;
