// declare var process: {
//     env: {
//         REACT_APP_STAGE: string
//     }
// }
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'ssss',
  authDomain: "crossworld-b3863.firebaseapp.com",
  projectId: "crossworld-b3863",
  storageBucket: "crossworld-b3863.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID || 'sss',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || 'sss',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'ssss'
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

const dev = {
  apiGateway: {
    API_SERVER_URL: "http://localhost:8080/",
    IMAGE_STORAGE_URL: "",
  },
};

const int = {
  apiGateway: {
    API_SERVER_URL: "https://devapi-bonus.evrgaming.com/", 
    IMAGE_STORAGE_URL: "",
  },
};

const prod = {
  apiGateway: {
    API_SERVER_URL: "https://evrcoupons.ivtech.co.il/",
    IMAGE_STORAGE_URL: "",
  },
};

const apiKeys = {
  GOOGLE_CLIENT_ID:
    "941897212701-6dlt7tmltri748hm5or1nc0c2o5u4463.apps.googleusercontent.com",
  FACEBOOK_CLIENT_ID: "489765169162454",
  APPLE_CLIENT_ID: "com.evrgaming.bonus.shop",
};

let config = int;
if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim() === 'production') {
    config = prod;
}
else if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim() === 'int') {
    config = int;
}

let exportConfig = {
  APP_VERSION: 0.1,
  ...config,
  apiKeys,
};
export default exportConfig;
