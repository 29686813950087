import React from "react";
import { FlexColumn, FlexRow } from "../../common/containers";
import { Typography } from "../../ui/Typography";
import { FaqContainer, OrderedList, QuestionAnswer, QuestionIconAnimated, Separator } from "./FaqStyles";
import { ReactComponent as WhatsApp } from "../../../assets/icons/whatsapp.svg";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";
import { ReactComponent as EVR } from "../../../assets/icons/nav-evr.svg";
import { useAppSelector } from "../../../hooks/reduxHooks";

const FAQ = [
  {
    question: "האם יש תוקף ליהלומים?",
    answer: "לא, את היהלומים תוכלו לנצל מתי שתרצו."
  },
  {
    question: "האם יש תוקף לקוד ההטבה?",
    answer: "כן, לכל הטבה יש את התוקף שלה לכן מומלץ לנצל את ההטבה בסמוך ככל האפשר לרכישה דרך חנות ההטבות שלנו בעת קבלת קוד הקופון."
  },
  {
    question: "האם ניתן להעביר יהלומים בין משתמשים? ",
    answer: "לא, היהלומים הינם אישיים ואין אפשרות להעבירם בין משתמשים."
  },
  {
    question: "איך אפשר לבטל הטבה שרכשתי? ",
    answer: "לא ניתן לבטל הטבות לאחר רכישתן. "
  }
]

export default function Faq() {
  const auth = useAppSelector((state) => state.auth);
  return (
    <FaqContainer style={{marginTop: (auth.authenticated ? '120px' : '90px')}}>
      <QuestionIconAnimated height={80} style={{ alignSelf: 'center' }} />
      <Typography varient="h3" style={{ textAlign: 'center' }}>איך זה עובד</Typography>
      <OrderedList>
        <li>צוברים יהלומים דרך המשחק</li>
        <li>נכנסים לחנות ההטבות דרך תפריט הבית</li>
        <li>רוכשים את ההטבה הרצויה </li>
        <li>מקבלים קוד קופון</li>
        <li>הולכים לאתר ההטבה למוצר הרצוי</li>
        <li>מזינים את קוד הקופון</li>
        <li>בוחרים את דרך האיסוף הרצויה</li>
        <li>ממתינים למשלוח ונהנים מההטבה המדהימה</li>
      </OrderedList>
      {FAQ.map((q, i) => {
        return <QuestionAnswer key={i} {...q} />
      })}
      <Separator />
      <p>
        יש לכם בעיה שלא מופיעה בשאלות ותשובות?<br />
        ניתן ליצור איתנו קשר ונעזור לכם באופן אישי.
      </p>
      <FlexColumn style={{ alignItems: 'center', padding: '0.5rem 1rem', direction: 'ltr' }}>
        <FlexRow style={{alignItems: 'center', padding: '5px 0'}}>
          <WhatsApp width={'20px'} style={{ padding: '0 5px' }} />
          <a href={"https://wa.me/+972559398090"} style={{color: 'var(--blue)'}}>+972 55-939-8090</a>
        </FlexRow>
        <FlexRow style={{alignItems: 'center', padding: '5px 0'}}>
          <Email width={'20px'} style={{ padding: '0 5px' }} />
          <a href={"mailto:support@crossworld.co.il"} style={{color: 'var(--blue)'}}>support@crossworld.co.il</a>
        </FlexRow>
      </FlexColumn>
      <EVR style={{ padding: '20px 0', alignSelf: 'center' }} />
    </FaqContainer>
  );
}
