import { useState, useEffect } from "react";

const emailRegEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,})/;
const onlyNumbersRegEx = /^[0-9]*$/;

const useRegExValidator = (regEx: RegExp, value: any) => {
    const [valid, setValid] = useState(true);
    useEffect(() => {
        setValid(!value || regEx.test(value))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])
    return valid;
}

export const useEmailValidator = (value: string) => {
    const isValid = useRegExValidator(emailRegEx, value) && !!value;;
    return isValid;
}
export const usePasswordValidator = (value: string) => {
    const isValid = useRegExValidator(passwordRegEx, value) && !!value;
    return isValid;
}

export const useOnlyNumbersValidator = (value: string) => {
    const isValid = useRegExValidator(onlyNumbersRegEx, value);
    return isValid;
}

export default useRegExValidator;
