import React, { useEffect } from 'react';
import { FlexColumnCentered } from '../common/containers';
import { InfinityLoader } from '../ui/Spinner';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { authCheckStart } from '../../store/features/auth-user/auth-user-slice';
import { useNavigate } from 'react-router-dom';
import Routes from '../../routes';

const SsoCallback = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		
		const access_token = Cookies.get('access_token');
		const refresh_token = Cookies.get('refresh_token');
		const id_token = Cookies.get('id_token');
		const expires_in = Cookies.get('expires_in');
		// console.log(access_token);
		// console.log(refresh_token);
		// console.log(id_token);
		// console.log(expires_in);
		if (access_token && refresh_token && id_token) {
			localStorage.setItem("access_token", access_token);
			localStorage.setItem("id_token", id_token);
			localStorage.setItem("refresh_token", refresh_token);
			localStorage.setItem("expires_in", JSON.stringify(expires_in));
			dispatch(authCheckStart());
		}
		navigate(Routes.Dashboard.home, {replace: true});
	}, []);

	return <FlexColumnCentered style={{ height: '100%' }}>
		<InfinityLoader />
	</FlexColumnCentered>
}

export default SsoCallback;
