import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { useAppDispatch } from "../hooks/reduxHooks";
import { authCheckStart } from "../store/features/auth-user/auth-user-slice";
import NavigationSwitch from "../components/navigation/NavigationSwitch";
import MainAppLayout from "../components/hoc/MainAppLayout";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authCheckStart());
  }, [dispatch]);

  return (
    <Router>
      <MainAppLayout>
        <NavigationSwitch />
      </MainAppLayout>
    </Router>
  );
}

export default App;
