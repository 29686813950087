import styled, { keyframes } from "styled-components";
import { FlexColumn, FlexRow } from "../../common/containers";
import { ReactComponent as QuestionMarkIcon } from "../../../assets/icons/question-mark.svg";
import { ReactComponent as GemIcon } from "../../../assets/icons/diamond-icon-pink.svg";

export const FaqContainer = styled(FlexColumn)`
	margin: 90px 10px;
	padding-bottom: 30px;
	direction: rtl;
`;

const rotate_animation = keyframes`
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
`;

export const QuestionIconAnimated = styled(QuestionMarkIcon)`
	align-self: center;
	z-index: -1;
	/* animation-name: ${rotate_animation};
	animation-duration: 12s;
	animation-delay:0s;
	animation-iteration-count: infinite; */
`;

export const OrderedList = styled.ol`
	border-bottom: 1px dashed #c4c4c4;
	padding-bottom: 1.5rem;
	& > li {
		margin: 5px;
	}
`

const QAContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;
	padding: 0 1rem;
`;

const Question = styled.p`
	font-size: 20px;
	font-weight: bold;
	padding: 0;
	margin: 0;
`;

const Answer = styled.p`
	padding: 0;
	margin: 5px;
	font-size: 18px;
`;

export const Separator = styled.div`
	border-bottom: 1px dashed #c4c4c4;
`;

export const QuestionAnswer = ({ question, answer }: any) => {
	return (
		<QAContainer>
			<FlexRow style={{alignItems: 'center'}}>
				<GemIcon style={{ padding: '0 5px'}}/>
				<Question>{question}</Question>
			</FlexRow>
			<Answer>{answer}</Answer>
		</QAContainer>
	)
}