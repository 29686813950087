import React, { useEffect } from "react";
import { IInfScrollProps } from "../../interfaces/components/infScroll/infiniteScroll-interface";
import { loadPage } from "../../store/features/bonus/bonus-slice";
import { InfinityLoader, VanillaSpinner } from "../ui/Spinner";

const LOAD_X_PX_BEFORE_BOT = 40;

export const InfiniteScroll: React.FC<IInfScrollProps> = ({
  loading,
  page,
  limit,
  totalItems,

  loadCallback,
  children,
}) => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.scrollHeight - LOAD_X_PX_BEFORE_BOT &&
      loading === false &&
      page < Math.ceil(totalItems / limit)
    ) {
      loadCallback();
    }
  };

  return (
    <>
      {children}
      {loading === true ? <InfinityLoader /> : null}
    </>
  );
};
