import axios, { AxiosInstance, HeadersDefaults } from 'axios';
import env from '../config';


interface CommonHeaderProperties extends HeadersDefaults {
    Authorization: string;
}

let headers = {
    client: 'web',
    clientVersion: env.APP_VERSION,
};

axios.defaults.withCredentials = false

const instance = axios.create({
    baseURL: env.apiGateway.API_SERVER_URL,
    headers: headers,
});
instance.defaults.withCredentials = false


export default instance;

export const updateToken = () => {
    instance.defaults.headers = {
        Authorization: localStorage.getItem('access_token')
    } as CommonHeaderProperties
}

export const removeToken = () => {
    instance.defaults.headers = {} as CommonHeaderProperties
}

export const updateToken2fa = (accessToken: string) => {
    instance.defaults.headers = {
        Authorization: accessToken
    } as CommonHeaderProperties
}
