import styled from "styled-components";
import PageLayout from "./PageLayout";
import {isMobile, isTablet} from 'react-device-detect';
import { FlexColumnCentered } from "../common/containers";
import { useTranslation } from "react-i18next";
import Background from '../../assets/images/bg.png';

const MainAppContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const MainAppLayout = ({ children }: { children: any }) => {

  const { t } = useTranslation();

  return (
    <>
      <MainAppContainer>
        {isMobile || isTablet ? 
          <PageLayout>{children}</PageLayout> : 
          <FlexColumnCentered 
            style={{
              alignItems: 'center', 
              textAlign: 'center', 
              flex: 1, 
              color: 'var(--primary-blue)', 
              fontSize: '30px', 
              fontWeight: 'bold',
              backgroundImage: `url(${Background})`
              }}>{t('navbar.onlyDesktop')}</FlexColumnCentered>}
      </MainAppContainer>
    </>
  );
};

export default MainAppLayout;
