import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../hooks/reduxHooks";
import Routes from "../../../routes";
import { submitContactUs } from "../../../store/features/auth-user/auth-user-slice";
import { FlexColumn, FlexColumnCentered, FlexRow } from "../../common/containers";
import { Button } from "../../ui/Buttons";
import FormInput from "../../ui/inputs/FormInput";
import { TextArea } from "../../ui/inputs/TextArea";
import { Typography } from "../../ui/Typography";
import { ContactsContainer } from "./ContactStyle";
import { ReactComponent as WhatsApp } from "../../../assets/icons/whatsapp.svg";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";
import { ReactComponent as EVR } from "../../../assets/icons/nav-evr.svg";

const Contact = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState<any>({});
  const [formSubmited, setFormSubmited] = useState(false);

  const dispatch = useDispatch();
  const auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (auth && auth.user) {
      console.log(auth);
      setForm({
        fullName: auth.user.fullName,
        email: auth.user.email,
        _id: auth.user._id,
        phone: auth.user.phone
      })
    }
  }, [auth]);

  const onSend = () => {
    dispatch(submitContactUs(form));
    setFormSubmited(true);
    setForm({});
  }

  return (
    <ContactsContainer style={{ marginTop: (auth.authenticated ? '120px' : '90px') }}>
      <Typography varient="h3" style={{ textAlign: 'center' }}>{t("contact.contactUs")}</Typography>
      {
        !formSubmited ?
          <FlexColumn>
            <FormInput value={form.fullName} placeholder={t("contact.fullName")} onChange={(e) => { setForm({ ...form, fullName: e.target.value }) }} varient={"contained"} />
            <FormInput value={form.email} placeholder={t("contact.email")} onChange={(e) => { setForm({ ...form, email: e.target.value }) }} varient={"contained"} />
            <FormInput value={form.phone} placeholder={t("contact.phone")} onChange={(e) => { setForm({ ...form, phone: e.target.value }) }} varient={"contained"} />
            <FormInput value={form.subject} placeholder={t("contact.subject")} onChange={(e) => { setForm({ ...form, subject: e.target.value }) }} varient={"contained"} />
            <TextArea value={form.description} placeholder={t("contact.description")} onChange={(e) => { setForm({ ...form, description: e.target.value }) }}></TextArea>
            <Button width={210} height={40} style={{ alignSelf: 'center' }} onClick={onSend}>
              {t("contact.send")}
            </Button>
          </FlexColumn>
          :
          <FlexColumnCentered>
            <Typography varient="body2" style={{ textAlign: 'center', padding: '30px 0' }}>{t("contact.thanks")}</Typography>
            <NavLink
              to={Routes.Dashboard.home}>
              <Typography varient='h4'>{t("navbar.home")}</Typography>
            </NavLink>
          </FlexColumnCentered>
      }
      <FlexColumn style={{ alignItems: 'center', padding: '2rem 1rem', direction: 'ltr' }}>
        <FlexRow style={{ alignItems: 'center', padding: '5px 0' }}>
          <WhatsApp width={'20px'} style={{ padding: '0 5px' }} />
          <a href={"https://wa.me/+972559398090"} style={{ color: 'var(--blue)' }}>+972 55-939-8090</a>
        </FlexRow>
        <FlexRow style={{ alignItems: 'center', padding: '5px 0' }}>
          <Email width={'20px'} style={{ padding: '0 5px' }} />
          <a href={"mailto:support@crossworld.co.il"} style={{ color: 'var(--blue)' }}>support@crossworld.co.il</a>
        </FlexRow>
      </FlexColumn>
      <EVR style={{ padding: '20px 0', alignSelf: 'center' }} />
    </ContactsContainer>
  );
}

export default Contact;