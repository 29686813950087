import React from "react";
import styled, { DefaultTheme, useTheme } from "styled-components";
import {
  IButtonProps,
  IIconButton,
} from "../../interfaces/components/ui/buttons";

export const Button = styled.button<IButtonProps>`
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: none;

  height: ${(props: IButtonProps) =>
    props.height ? `${props.height}px` : "100%"};
  width: ${(props: IButtonProps) =>
    props.width ? `${props.width}px` : "100%"};
  padding: 3px 10px;
  text-align: center;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: default;
    &:hover {
      background-color: ${(props) =>
        props.theme.palette.buttons.hover.disabled};
    }
    background-color: ${(props) =>
      props.theme.palette.buttons.background.disabled};
    color: ${(props) => props.theme.palette.buttons.typography.disabled};
  }
  ${(props) => {
    switch (props.varient) {
      case "primary":
        return props.theme.buttons.varient.primary;
      case "secondery":
        return props.theme.buttons.varient.secondery;
      case "info":
        return props.theme.buttons.varient.info;
      default:
        return props.theme.buttons.varient.primary;
    }
  }}
  &:hover {
    background-color: ${(props) =>
      props.theme.palette.buttons.hover[props.varient || "primary"]};
  }
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;

const IconContainer = styled.button`
  display: flex;
  cursor: pointer;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
`;

export const IconButton = (props: IIconButton) => {
  const { onClick, containerStyles, disabled, Icon, color } = props;
  const theme: DefaultTheme = useTheme();

  return (
    <IconContainer
      style={{ ...containerStyles }}
      disabled={disabled}
      onClick={onClick}
    >
      {Icon && (
        <Icon
          fill={
            (color && theme.palette.typography[color]) ||
            theme.palette.typography.primary
          }
        />
      )}
    </IconContainer>
  );
};
