import styled from "styled-components";
import TopNavigation from "../navigation/TopNavigation";

const LayoutContainer = styled.div`
  width: 100%;
`;

const PageLayout = ({ children }: { children: any }) => {
  return (
    <LayoutContainer>
      <TopNavigation />
      {children}
    </LayoutContainer>
  );
};

export default PageLayout;
