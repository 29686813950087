import { useTranslation } from "react-i18next";
import { Typography } from "../ui/Typography";
import { GemsContainer, UserHeaderContainer } from "./UserHeaderStyle";
import { ReactComponent as Icon } from "../../assets/icons/diamond-icon-blue.svg";
import { IUserProps } from "../../interfaces/models/user";
import { FlexRow, FlexRowCentered } from "../common/containers";

export const UserHeader = (props: IUserProps) => {
  const { t } = useTranslation();
  return (
    <UserHeaderContainer>
      <GemsContainer>
        <FlexRowCentered style={{gap: '5px'}}>
          <Icon />
          <Typography varient='h4'>
            {props.user.gems}
          </Typography>
        </FlexRowCentered>
      </GemsContainer>
      <Typography varient='h4' style={{ direction: 'rtl' }}>
        {t("userHeader.greet")}
        {props.user.fullName}
      </Typography>
    </UserHeaderContainer>
  );
};
