export const AuthRoutes = {
  login: "/login",
  sso: "/sso/callback",
  logout: "/logout",
};

export const DashboardRoutes = {
  home: "/home",
  profile: "/profile",
  contact: "contact",
  termsOfUse: "/terms-of-use",
  privacy: "/privacy",
  faq: "/faq",
  history: "/history",
  bonus: "/bonus/:id",
};

const Routes = {
  Auth: AuthRoutes,
  Dashboard: DashboardRoutes,
};

export default Routes;
