import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IError } from "../../../interfaces/base";
import { ILoginForm } from "../../../interfaces/components/forms/login";
import { IBonus } from "../../../interfaces/models/bonus";
import { IBonusState } from "../../../interfaces/store/bonus-interface";

const initialState: IBonusState = {
  bonusesArray: [],
  metadata: { page: 0, totalItems: 0, limit: 0 },
  loading: true,
  error: null,
};

const _loadPage = (state: IBonusState, { payload }: PayloadAction<{ page: number; RESULTS_PER_LOAD_LIMIT: number }>) => {
  if (payload.page === 0) {
    state.bonusesArray = [];
  }
  state.loading = true;
  state.error = null;
};
const _clearBonusArray = (state: IBonusState) => {
  state.bonusesArray = [];
};

const _fetchPageSuccess = (
  state: IBonusState,
  {
    payload,
  }: PayloadAction<{
    bonus: IBonus[];
    metadata: {
      page: number;
      totalItems: number;
      limit: number;
    };
  }>
) => {
  state.bonusesArray = [...state.bonusesArray!, ...payload.bonus];
  state.metadata.page = payload.metadata.page;
  state.metadata.totalItems = payload.metadata.totalItems;
  state.metadata.limit = payload.metadata.limit;

  state.loading = false;
  state.error = null;
};

const _fetchFail = (state: IBonusState, { payload }: PayloadAction<{ message: string }>) => {
  state.bonusesArray = [];
  state.metadata.page = 0;
  state.metadata.totalItems = 0;
  state.metadata.limit = 0;

  state.loading = false;
  state.error = payload;
};

const BonusSlice = createSlice({
  name: "bonus",
  initialState,
  reducers: {
    fetchFail: _fetchFail,
    loadPage: _loadPage,
    fetchPageSuccess: _fetchPageSuccess,
    clearBonusArray: _clearBonusArray,
  },
});

const { actions, reducer } = BonusSlice;
export const { fetchFail, loadPage, fetchPageSuccess, clearBonusArray } =
  actions;

export default reducer;
