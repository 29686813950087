import React from 'react';
import styled from 'styled-components';

import { IInputUI } from '../../../interfaces/components/ui/inputs';

export const TextArea = styled.textarea<IInputUI>((props: IInputUI) => {
    const varient = props.varient || "contained";
    const inputTheme = props.theme.inputs.varient[varient];

    return `
        height: 150px;
        border: none;
        font-weight: 400;
        font-size: 16px;
        padding: 20px;
        margin: 20px 0;
        background-color: ${inputTheme.backgroundColor};
        border: 1px solid #C4C4C4;
        color: rgb(53, 59, 125);
        font-family: ${inputTheme.fontFamily};
        resize: none;      
        &.invalid{
            border-bottom: 1px solid ${props.theme.palette.error.light};
        }
        &:focus {
            outline: none;
        }
        ::placeholder {
            color: ${inputTheme.placeholder};
        }
    `
});