import axiosInstance, { updateToken2fa } from "../../config/axios";
import ServerRoutes from "./routes";
import { handleAxiosError } from "./utils/common";

const getAllBonuses = async (page: number, limit: number) => {
  try {
    const { status, data } = await axiosInstance.get(
      ServerRoutes.bonus.getAll,
      { headers: { page, limit } }
    );
    return { status, data };
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message =
      "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 404:
        message = "Bonus not found";
        break;
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

const getBonusById = async (bonusId: string) => {
  try {
    const { status, data } = await axiosInstance.get(
      ServerRoutes.bonus.getWithId + bonusId
    );
    return { status, data };
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message =
      "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 404:
        message = "Bonus not found";
        break;
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

const redeemBonus = async (bonusId: string) => {
  try {
    const { status, data } = await axiosInstance.post(ServerRoutes.coupon.redeem, { bonusId: bonusId });
    return { status, data };
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message =
      "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 404:
        message = "Bonus not found";
        break;
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

const BonusApi = {
  getAllBonuses,
  getBonusById,
  redeemBonus,
};

export default BonusApi;
