import React from "react";
import { useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import AppleLogin from "react-apple-login";
import { gapi } from "gapi-script";

import { useEmailValidator, usePasswordValidator } from "../../hooks/validators/useRegExValidator";
import { useForm } from "../../hooks/useForm";
import { ILoginForm } from "../../interfaces/components/forms/login";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { failed, loginAppleStart, loginFacebookStart, loginGoogleStart, loginLoadingStart, loginStart } from "../../store/features/auth-user/auth-user-slice";
import { Container, CrossworldLoadingButton, LoginWith, Spinnercontainer, StyledLoginText, TermsLink } from "./LoginStyles";
import { ReactComponent as GoogleIcon } from "../../assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as AppleIcon } from "../../assets/icons/apple.svg";
import { ReactComponent as CrossworldIcon } from '../../assets/icons/crossworld.svg';
import { ReactComponent as CaretIcon } from '../../assets/icons/caret-left.svg';
import env from "../../config";
import { InfinityLoader } from "../ui/Spinner";
import Routes from "../../routes";
import { FlexColumn, FlexRow } from "../common/containers";

const LoginView = (props: { onLoginSubmit: any; setView: any }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { onLoginSubmit, setView } = props;
  const auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        clientId: env.apiKeys.GOOGLE_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);

    const script = document.createElement("script");

    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    script.src = `https://connect.facebook.net/en_US/sdk.js`;

    document.body.appendChild(script);

    window.fbAsyncInit = () => {
      FB.init({
        appId: env.apiKeys.FACEBOOK_CLIENT_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v11.0",
      });
    };

    return () => {
      // delete window.fbAsyncInit;
      document.body.removeChild(script);
    };
  }, []);

  const {
    handleSubmit,
    handleChange,
    data: loginState,
    errors,
  } = useForm<ILoginForm>({
    validations: {
      email: {
        custom: {
          isValid: () => emailValidation,
          message: "כתובת אימייל לא תקינה",
        },
      },
      password: {
        custom: {
          isValid: () => passwordValidation,
          message: "הסיסמה צריכה להכיל אות גדולה, אות קטנה, מספר ותו מיוחד",
        },
      },
    },
    onSubmit: () => {
      if (onLoginSubmit) {
        onLoginSubmit(loginState);
      }
    },
  });

  const emailValidation = useEmailValidator(loginState.email);
  const passwordValidation = usePasswordValidator(loginState.password);

  const onLoginClick = () => {
    handleSubmit();
  };

  useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        clientId: env.apiKeys.GOOGLE_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);

    const script = document.createElement("script");

    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    script.src = `https://connect.facebook.net/en_US/sdk.js`;

    document.body.appendChild(script);

    window.fbAsyncInit = () => {
      FB.init({
        appId: env.apiKeys.FACEBOOK_CLIENT_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v11.0",
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onGoogleSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline): void => {
    dispatch(
      loginGoogleStart({
        tokenId: (res as GoogleLoginResponse).tokenId,
      })
    );
  };

  const handleFacebookLogin = async () => {
    // dispatch(loginLoadingStart());
    try {
      FB.login((response) => {
        if (response.status === "connected")
          dispatch(
            loginFacebookStart({
              access_token: response.authResponse.accessToken,
            })
          );
      });
    } catch (err: any) {
      dispatch(failed(err));
    }
  };

  const handleAppleLogin = async (data: any) => {
    if (data.authorization) {
      dispatch(
        loginAppleStart({
          access_token: data.authorization.id_token,
        })
      );
    }
  }

  return (
    <Container>
          {/* <CaretIcon style={{margin: '0px 18px'}}/> */}
      <CrossworldLoadingButton>
        <FlexRow style={{alignItems: 'center'}}>
          <CrossworldIcon width={'48px'} style={{margin: '0px 18px'}}/>
        </FlexRow>
        <FlexColumn style={{flex: 1, margin: '0 18px', textAlign: 'right'}}>
          <StyledLoginText style={{ color: '#353B7D', fontSize: '21px', margin: '0' }}>{t("login.connect")}</StyledLoginText>
          <StyledLoginText style={{ color: '#353B7D', fontSize: '14px', margin: '0' }}>Crossworld</StyledLoginText>
        </FlexColumn>
      </CrossworldLoadingButton>
      {auth.loading ? <InfinityLoader /> : null}
      {/* <GoogleLogin
        clientId={env.apiKeys.GOOGLE_CLIENT_ID}
        buttonText="Sign in with Google"
        onSuccess={onGoogleSuccess}
        cookiePolicy={"single_host_origin"}
        isSignedIn={false}
        render={(renderProps) => (
          <LoginWith variant="var(--login-google);" onClick={()=> {
            // dispatch(loginLoadingStart());
            renderProps.onClick();
          }} style={{ direction: 'rtl' }}>
            <GoogleIcon style={{ margin: '0 10px' }} />
            <StyledLoginText style={{ color: '#000' }}>{t("login.google")}</StyledLoginText>
          </LoginWith>
        )}
      />

      <LoginWith variant="var(--login-facebook);" onClick={handleFacebookLogin} style={{ direction: 'rtl' }}>
        <FacebookIcon style={{ margin: '0 10px' }} />
        <StyledLoginText>{t("login.facebook")}</StyledLoginText>
      </LoginWith>
      <AppleLogin
        clientId={env.apiKeys.APPLE_CLIENT_ID}
        redirectURI={window.location.href}
        usePopup={true}
        callback={handleAppleLogin}
        scope="email name"
        render={renderProps => (
          <LoginWith variant="var(--login-apple);" style={{ direction: 'rtl' }} onClick={()=> {
            // dispatch(loginLoadingStart());
            renderProps.onClick();
          }}>
            <AppleIcon style={{ margin: '0 10px' }} />
            <StyledLoginText>{t("login.apple")}</StyledLoginText>
          </LoginWith>
        )} />
      <TermsLink to={Routes.Dashboard.termsOfUse}>
        {t("login.terms")}
      </TermsLink> */}
    </Container>
  );
};

const LoginForm = (props: { with2fa?: boolean; loginAction?: (data: ILoginForm) => any; verifyCodeAction?: any; resendCodeAction?: any; resetPasswordAction?: any }) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const [view, setView] = useState<"login" | "2fa" | "reset">("login");
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    const path = params.get('redirect');
    if (auth && auth.authenticated && auth.user && path) {
      navigate(path, { replace: true });
    }
    else if (auth && auth.authenticated && auth.user) {
      navigate("/home", { replace: true });
    }
  }, [auth]);

  const onLoginSubmit = (data: ILoginForm) => {
    // const { email, password } = data;
    // dispatch(
    //   loginStart({
    //     email,
    //     password,
    //   })
    // );
  };

  return <LoginView onLoginSubmit={onLoginSubmit} setView={setView} />;
};

export default LoginForm;
