import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { authLogoutStart } from "../../store/features/auth-user/auth-user-slice";

const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(authLogoutStart());
    navigate("/home");
  }, []);
  return null;
};

export default React.memo(Logout);
