import React from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";

import Routes from "../../routes";
import BonusPage from "../bonusPage/BonusPage";
import Home from "../home/Home";
import Login from "../login/Login";
import Logout from "../logout/Logout";
import Contact from "../pages/contact/Contact";
import Faq from "../pages/faq/Faq";
import History from "../pages/history/History";
import Privacy from "../pages/privacy/Privacy";
import Profile from "../pages/profile/Profile";
import TermsOfUse from "../pages/termsOfUse/TermsOfUse";
import SsoCallback from "../sso/SSOCallback";

const NavigationSwitch = () => {
  return (
    <Switch>
      <Route path={Routes.Dashboard.home} element={<Home />} />
      <Route path={Routes.Dashboard.contact} element={<Contact />} />
      <Route path={Routes.Dashboard.profile} element={<Profile />} />
      <Route path={Routes.Dashboard.termsOfUse} element={<TermsOfUse />} />
      <Route path={Routes.Dashboard.privacy} element={<Privacy />} />
      <Route path={Routes.Dashboard.faq} element={<Faq />} />
      <Route path={Routes.Dashboard.history} element={<History />} />
      <Route path={Routes.Dashboard.bonus} element={<BonusPage />} />
      <Route path={Routes.Auth.logout} element={<Logout />} />
      <Route path={Routes.Auth.login} element={<Login />} />
      <Route path={Routes.Auth.sso} element={<SsoCallback />} />
      <Route
        path='*'
        element={<Navigate to={Routes.Dashboard.home} replace />}
      />
    </Switch>
  );
};

export default NavigationSwitch;
