import styled from "styled-components";

import { IStyledImage } from "../../interfaces/components/base/image";

export const StyledRoundImage = styled.img`
  width: ${(props: IStyledImage) => props.width + "px"};
  height: ${(props: IStyledImage) => props.height + "px"};
  border-radius: ${(props: IStyledImage) =>
    (props.radius ? props.radius : props.width / 2) + "px"};
`;
