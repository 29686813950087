import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Nav from "./NavBarStyle";
import Routes from "../../routes";
import { Typography } from "../ui/Typography";
import { ReactComponent as IconHome } from "../../assets/icons/home.svg";
import { ReactComponent as IconProfile } from "../../assets/icons/profile.svg";
import { ReactComponent as IconContact } from "../../assets/icons/contact.svg";
import { ReactComponent as IconTerms } from "../../assets/icons/terms.svg";
import { ReactComponent as IconFaq } from "../../assets/icons/faq.svg";
import { ReactComponent as IconHistory } from "../../assets/icons/history.svg";
import { ReactComponent as IconLogin } from "../../assets/icons/login.svg";
import { ReactComponent as IconLogout } from "../../assets/icons/logout.svg";
import { useAppSelector } from "../../hooks/reduxHooks";
import { Iprops } from "../../interfaces/components/navigation/navigation";

export const SideMenu: React.FC<Iprops> = (props) => {
  const auth = useAppSelector((state) => state.auth);

  const { t } = useTranslation();
  return (
    <Nav.StyledMenu>
      <Nav.StyledItems>
        <Nav.StyledContainerTop>
          <Nav.StyledItem onClick={props.toggleMenu}>
            <NavLink
              to={Routes.Dashboard.home}>
              <Typography varient='h4'>{t("navbar.home")}</Typography>
            </NavLink>
            <IconHome />
          </Nav.StyledItem>
          {/* <Nav.StyledItem onClick={props.toggleMenu}>
            <NavLink
              to={
                auth.user === null
                  ? Routes.Auth.login
                  : Routes.Dashboard.profile
              }
            >
              <Typography varient='h4'>{t("navbar.profile")}</Typography>
            </NavLink>
            <IconProfile />
          </Nav.StyledItem> */}

          <Nav.StyledItem onClick={props.toggleMenu}>
            <NavLink to={Routes.Dashboard.contact}>
              <Typography varient='h4'>{t("navbar.contact")}</Typography>
            </NavLink>
            <IconContact />
          </Nav.StyledItem>

          <Nav.StyledItem onClick={props.toggleMenu}>
            <NavLink to={Routes.Dashboard.termsOfUse}>
              <Typography varient='h4'>{t("navbar.terms")}</Typography>
            </NavLink>
            <IconTerms />
          </Nav.StyledItem>

          <Nav.StyledItem onClick={props.toggleMenu}>
            <NavLink to={Routes.Dashboard.privacy}>
              <Typography varient='h4'>{t("navbar.privacy")}</Typography>
            </NavLink>
            <IconTerms />
          </Nav.StyledItem>

          <Nav.StyledItem onClick={props.toggleMenu}>
            <NavLink to={Routes.Dashboard.faq}>
              <Typography varient='h4'>{t("navbar.faq")}</Typography>
            </NavLink>
            <IconFaq />
          </Nav.StyledItem>

          {/* <Nav.StyledItem onClick={props.toggleMenu}>
            <NavLink to={auth.user === null
                  ? Routes.Auth.login
                  : Routes.Dashboard.history}>
              <Typography varient='h4'>{t("navbar.history")}</Typography>
            </NavLink>
            <IconHistory />
          </Nav.StyledItem> */}
        </Nav.StyledContainerTop>

        <Nav.StyledContainerBtm>
          <Nav.StyledItem onClick={props.toggleMenu}>
            {/* {auth.user ?login element :logout} */}
            {!auth.user ? (
              <>
                <NavLink to={Routes.Auth.login}>
                  <Typography varient='h4'>{t("navbar.login")}</Typography>
                </NavLink>
                <IconLogin />
              </>
            ) : (
              <>
                <NavLink to={Routes.Auth.logout}>
                  <Typography varient='h4'>{t("navbar.logout")}</Typography>
                </NavLink>
                <IconLogout />
              </>
            )}
          </Nav.StyledItem>
        </Nav.StyledContainerBtm>
      </Nav.StyledItems>
    </Nav.StyledMenu>
  );
};
