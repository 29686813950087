import AuthUserSlice from "./auth-user/auth-user-slice";
import BonusSlice from "./bonus/bonus-slice";
import bonusDetailsSlice from "./bonusDetails/bonusDetails-slice";
import redeemBonusSlice from './bonusDetails/redeem-bonus-slice';

const reducers = {
  auth: AuthUserSlice,
  bonus: BonusSlice,
  bonusDetails: bonusDetailsSlice,
  redeemBonus: redeemBonusSlice
};

export default reducers;
