import { IAppleLoginForm } from './../../../interfaces/components/forms/login';
import { PayloadAction } from "@reduxjs/toolkit";
import { call, fork, put, takeLatest, delay } from "redux-saga/effects";
import jwt_decode from "jwt-decode";

import { DecodedData } from "./../../../utils/utils";
import { removeToken, updateToken } from "./../../../config/axios";
import { IBaseResponse } from "./../../../interfaces/base";
import {
  authCheckStart,
  authCheckFailed,
  authCheckSuccess,
  loginStart,
  loginFailed,
  loginSuccess,
  verifyCodeStart,
  verifyCodeFailed,
  verifyCodeSuccess,
  authLogoutSuccess,
  failed,
  success,
  authLogoutStart,
  resendCodeStart,
  loginGoogleStart,
  loginFacebookStart,
  authLogoutFaild,
  submitContactUs,
  loginAppleStart,
} from "./auth-user-slice";
import authApi from "../../api/auth-user-api";
import { getTokenRefreshTime } from "../../../utils/utils";
import { IFacebookLoginForm, IGoogleLoginForm } from "../../../interfaces/components/forms/login";

function* authCheckState(): any {
  try {
    const token = localStorage.getItem("access_token");
    const id_token = localStorage.getItem("id_token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (!token) {
      localStorage.clear();
      return yield put(authLogoutSuccess());
    }
    updateToken();
    const decoded: DecodedData = jwt_decode(token);
    const expiration = decoded.exp;
    const refreshTime = getTokenRefreshTime(token);

    if ((!expiration || expiration < new Date().getTime() / 1000) && refresh_token) {
      const { status, data, error } = yield call(authApi.refreshTokenLogin, refresh_token);
      if (status === 200 && data.access_token) {
        const { access_token, id_token, refresh_token, expires_in } = data;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("id_token", id_token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("expires_in", JSON.stringify(expires_in));

        updateToken();
        yield fork(scheduleTokenRefresh, refreshTime);
        const userInfoResponse = yield call(authApi.userInfo);
        if (userInfoResponse.status !== 200) {
          localStorage.clear();
          return yield put(authCheckFailed({ message: "Can't fetch user info" }));
        }
        const user = userInfoResponse.data.data;
        if (user) {
          return yield put(authCheckSuccess({ user }));
        } else {
          localStorage.clear();
          return yield put(authCheckFailed({ message: "Invalid token provided" }));
        }
      } else {
        localStorage.clear();
        removeToken();
        return yield put(authLogoutSuccess());
      }
    } else {
      updateToken();
      yield fork(scheduleTokenRefresh, refreshTime);
      const { status, data } = yield call(authApi.userInfo);
      if (status !== 200) {
        localStorage.clear();
        return yield put(authCheckFailed({ message: "Invalid token provided" }));
      }
      const user = data.data;
      if (user) {
        return yield put(authCheckSuccess({ user }));
      } else {
        localStorage.clear();
        return yield put(authCheckFailed({ message: "Invalid token provided" }));
      }
    }
  } catch (error: any) {
    return yield put(authCheckFailed(error));
  }
}

function* sendLoginRequest(action: PayloadAction<{ email: any; password: any }>): any {
  try {
    const { email, password } = action.payload;
    const { data, status, error } = yield call(authApi.login, {
      email,
      password,
    });

    if (error) {
      yield put(loginFailed(error));
      return;
    }

    yield put(loginSuccess(data.data));
    const { access_token, id_token, refresh_token, expires_in } = data.data;
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("id_token", id_token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("expires_in", JSON.stringify(expires_in));
    updateToken();
    const time = getTokenRefreshTime(access_token);
    yield fork(scheduleTokenRefresh, time);
    const userInfoResponse = yield call(authApi.userInfo);
    if (userInfoResponse.status !== 200) {
      localStorage.clear();
      return yield put(authCheckFailed({ message: "Invalid token provided" }));
    }
    const user = userInfoResponse.data.data;
    yield put(verifyCodeSuccess({ user: user }));
  } catch (error: any) {
    yield put(loginFailed(error));
  }
}

function* sendGoogleLoginRequest(action: PayloadAction<IGoogleLoginForm>): any {
  try {
    const { tokenId } = action.payload;
    const { data, status, error } = yield call(authApi.loginGoogle, {
      tokenId,
    });

    if (error) {
      yield put(loginFailed(error));
      return;
    }

    yield put(loginSuccess(data));
    const { access_token, id_token, refresh_token, expires_in } = data;
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("id_token", id_token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("expires_in", JSON.stringify(expires_in));
    updateToken();
    const time = getTokenRefreshTime(access_token);
    yield fork(scheduleTokenRefresh, time);
    const userInfoResponse = yield call(authApi.userInfo);
    if (userInfoResponse.status !== 200) {
      localStorage.clear();
      return yield put(authCheckFailed({ message: "Invalid token provided" }));
    }
    const user = userInfoResponse.data.data;
    yield put(verifyCodeSuccess({ user: user }));
  } catch (error: any) {
    yield put(loginFailed(error));
  }
}
function* sendFacebookLoginRequest(action: PayloadAction<IFacebookLoginForm>): any {
  try {

    const { data, status, error } = yield call(authApi.loginFacebook, {
      access_token: action.payload.access_token,
    });
    if (error) {
      yield put(loginFailed(error));
      return;
    }
    yield put(loginSuccess(data));
    const { access_token, id_token, refresh_token, expires_in } = data;
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("id_token", id_token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("expires_in", JSON.stringify(expires_in));
    updateToken();
    const time = getTokenRefreshTime(access_token);
    yield fork(scheduleTokenRefresh, time);
    const userInfoResponse = yield call(authApi.userInfo);
    if (userInfoResponse.status !== 200) {
      localStorage.clear();
      return yield put(authCheckFailed({ message: "Invalid token provided" }));
    }
    const user = userInfoResponse.data.data;
    yield put(verifyCodeSuccess({ user: user }));
  } catch (error: any) {
    yield put(loginFailed(error));
  }
}

function* sendAppleLoginRequest(action: PayloadAction<IAppleLoginForm>): any {
  try {

    const { data, status, error } = yield call(authApi.loginApple, {
      access_token: action.payload.access_token,
    });
    if (error) {
      yield put(loginFailed(error));
      return;
    }
    yield put(loginSuccess(data));
    const { access_token, id_token, refresh_token, expires_in } = data;
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("id_token", id_token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("expires_in", JSON.stringify(expires_in));
    updateToken();
    const time = getTokenRefreshTime(access_token);
    yield fork(scheduleTokenRefresh, time);
    const userInfoResponse = yield call(authApi.userInfo);
    if (userInfoResponse.status !== 200) {
      localStorage.clear();
      return yield put(authCheckFailed({ message: "Invalid token provided" }));
    }
    const user = userInfoResponse.data.data;
    yield put(verifyCodeSuccess({ user: user }));
  } catch (error: any) {
    yield put(loginFailed(error));
  }
}

function* resend2faCode(action: PayloadAction<{ token: string }>): any {
  try {
    const { data, status, error } = yield call(authApi.resendCode, action.payload.token);
    if (error) {
      yield put(failed(error));
      return;
    }
    yield put(success());
  } catch (error: any) {
    yield put(failed(error));
  }
}

function* send2faRequest(action: PayloadAction<{ code: string; token: string }>) {
  try {
    const { code, token } = action.payload;
    const { data, status, error }: IBaseResponse = yield call(authApi.verify2faCode, code, token);

    if (error) {
      yield put(verifyCodeFailed(error));
      return;
    }
    if (status === 200 && data.access_token) {
      const { access_token, id_token, refresh_token, expires_in } = data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("id_token", id_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("expires_in", JSON.stringify(expires_in));
      updateToken();
      const time = getTokenRefreshTime(data.access_token);
      yield fork(scheduleTokenRefresh, time);
      const user = getDecodedInfo(id_token);
      if (user) {
        yield put(verifyCodeSuccess({ user: user }));
      } else {
        localStorage.clear();
        yield put(verifyCodeFailed({ message: "Invalid token provided" }));
      }
    } else {
      localStorage.clear();
    }
  } catch (error: any) {
    yield put(verifyCodeFailed(error));
  }
}

function* authUserLogout(): any {
  try {
    let refreshToken = localStorage.getItem("refresh_token");
    if (refreshToken) {
      const { data, status, error }: IBaseResponse = yield call(authApi.logout, refreshToken);
      console.log(data, status);
      localStorage.clear();
      removeToken();
      if (status === 200 && data.ok) {
        return yield put(authLogoutSuccess());
      } else {
        return yield put(authLogoutFaild());
      }
    }
    localStorage.clear();
    removeToken();
    return yield put(authLogoutSuccess());
  } catch (error: any) {
    yield put(failed({ message: error.message }));
  }
}

export const getDecodedInfo = (token: string | null) => {
  try {
    if (token) {
      const decoded = jwt_decode(token);
      return decoded;
    }
    return null;
  } catch (error) {
    return null;
  }
};

function* loadTokens() {
  try {
    let refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) {
      return "refresh token not found";
    }
    const { status, data, error } = yield call(authApi.refreshTokenLogin, refreshToken);
    if (status === 200 && data.access_token) {
      const { access_token, id_token, refresh_token, expires_in } = data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("id_token", id_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("expires_in", JSON.stringify(expires_in));

      updateToken();
      const time = getTokenRefreshTime(data.access_token);
      yield fork(scheduleTokenRefresh, time);
    }
    return error;
  } catch (error) {
    return error;
  }
}

function* scheduleTokenRefresh(timer: number): any {
  try {
    yield delay(timer);
    const error = yield call(loadTokens);
    if (error) {
      yield put(authUserLogout());
    }
  } catch (error) {
    return;
  }
}

function* sendContactUs(action: PayloadAction<any>): any {
  try {
    const { status, error } = yield call(authApi.contactUs, action.payload);
    if (status != 200 || error) {
      yield put(failed(error));
      return;
    }
    yield put(success());
  } catch (error: any) {
    yield put(failed(error));
  }
}

function* authUserSaga() {
  yield takeLatest(loginStart.type, sendLoginRequest);
  yield takeLatest(verifyCodeStart.type, send2faRequest);
  yield takeLatest(authCheckStart.type, authCheckState);
  yield takeLatest(authLogoutStart.type, authUserLogout);
  yield takeLatest(resendCodeStart.type, resend2faCode);
  yield takeLatest(loginGoogleStart.type, sendGoogleLoginRequest);
  yield takeLatest(loginFacebookStart.type, sendFacebookLoginRequest);
  yield takeLatest(loginAppleStart.type, sendAppleLoginRequest);
  yield takeLatest(submitContactUs.type, sendContactUs);
}

export default authUserSaga;
