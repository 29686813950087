import React from "react";
import styled from "styled-components";

import {
  IInputProps,
  IInputUI,
} from "../../../interfaces/components/ui/inputs";
import { RelativeContainer } from "../../common/containers";

const Container = styled(RelativeContainer)`
  display: flex;
`;
export const StyledInput = styled.input<IInputUI>((props: IInputUI) => {
  const varient = props.varient || "contained";
  const inputTheme = props.theme.inputs.varient[varient];
  return `
    box-sizing: border-box;
    height: 50px;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border: none;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.408px;
    margin: 0 auto;
    padding:${varient === "contained" ? "0 28px" : "14px 17px"};
    border-radius: 0;
    background-color: ${inputTheme.backgroundColor};
    border: 1px solid #C4C4C4;
    border-radius: 0;
    color: rgb(53, 59, 125);;
    font-family: ${inputTheme.fontFamily};
    &.invalid{
        border-bottom: 1px solid ${props.theme.palette.error.light};
    }
    &:focus {
        outline: none;
    }
    ::placeholder {
        color: ${inputTheme.placeholder};
    }
    @media screen and (min-width:400px){
      width: 100%;
    }
    `;
});

const IconContainer = styled.div`
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  padding: 4px 13px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
`;
const Input = (props: IInputProps) => {
  const {
    placeholder,
    value,
    onChange,
    onIconClick,
    varient,
    disabled,
    valid,
    withValid,
    Icon,
    containerStyles,
    type,
  } = props;

  return (
    <Container style={containerStyles}>
      {Icon ? (
        <IconContainer onClick={onIconClick}>
          <Icon />
        </IconContainer>
      ) : null}
      <StyledInput
        dir='rtl'
        varient={varient}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        type={type || "text"}
        className={!withValid ? "" : valid ? "invalid" : ""}
      />
    </Container>
  );
};

export default Input;
