import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import sanitizeHtml from "sanitize-html";

import { FlexColumnCentered, FlexRowSpaced } from "../common/containers";
import { ReactComponent as GemsIcon } from "../../assets/icons/diamond-icon-blue.svg";
import {
  BonusPageContainer,
  PageBounsImg,
  PageBonusCardTitle,
  PageLogo,
  HeaderContainer,
  PageBonusSubHeader,
  BonusPageButtonContainer,
  BonusParagraph,
  ShortDescriptionDisplay,
  ItemDescription,
  BonusPageFooter,
  BonusPageDetailsContainer,
} from "./bonusPageStyle";
import { Button } from "../ui/Buttons";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { InfinityLoader } from "../ui/Spinner";
import { NotFoundMessage } from "../home/HomeStyles";
import ConfirmBonus from "./confirmBonusPopup/ConfirmBonus";
import { getBonusById } from "../../store/features/bonusDetails/bonusDetails-slice";
import Routes from "../../routes";

export default function BonusPage() {
  const dispatch = useAppDispatch();
  const bonusDetailsState = useAppSelector((state) => state.bonusDetails);
  const auth = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();
  const { id } = useParams();
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    dispatch(getBonusById({ bonusId: id }));
  }, []);

  const togglePopup = () => {
    setIsPopUpOpen(!isPopUpOpen);
  };

  const mapDescription = () => {
    if (bonusDetailsState.bonusDetails && bonusDetailsState.bonusDetails.description) {
      return (
        <>
          {bonusDetailsState.bonusDetails.description.map((value, index) => {
            const clean = sanitizeHtml(value, {
              allowedTags: ["ul", "li", "p", "strong"],
              allowedAttributes: { p: ["style"], li: ["style"], ul: ["style"] },
            });
            return (
              <React.Fragment key={value + index}>
                <BonusParagraph varient="mainText" dangerouslySetInnerHTML={{ __html: clean }}></BonusParagraph>
              </React.Fragment>
            );
          })}
        </>
      );
    } else return null;
  };

  const toLoginPage = () => {
    navigate(Routes.Auth.login + '?redirect=' + location.pathname);
  }

  return (
    <BonusPageContainer style={{marginTop: (auth.authenticated ? '125px' : '80px')}}>
      {bonusDetailsState.loading ? (
        <InfinityLoader />
      ) : null}
      {bonusDetailsState.bonusDetails ? (
        <>
          <BonusPageDetailsContainer style={{height: (auth.authenticated ? 'calc(100vh - 240px)' : 'calc(100vh - 200px)')}}>
            <PageBounsImg src={bonusDetailsState.bonusDetails.image} alt="img" />
            <FlexRowSpaced>
              <HeaderContainer>
                <PageBonusCardTitle>{bonusDetailsState.bonusDetails.title}</PageBonusCardTitle>
                <PageBonusSubHeader>
                  {bonusDetailsState.bonusDetails.evrCurrency?.toLocaleLowerCase() === "gems" ? <GemsIcon style={{ margin: '10px 5px' }} /> : null}
                  <FlexColumnCentered style={{ fontWeight: 'bold' }}>
                    {bonusDetailsState.bonusDetails.priceEvrCurrency} + {bonusDetailsState.bonusDetails.currency}
                    {bonusDetailsState.bonusDetails.price}
                  </FlexColumnCentered>
                </PageBonusSubHeader>
              </HeaderContainer>
              <PageLogo src={bonusDetailsState.bonusDetails.logo} />
            </FlexRowSpaced>
            <ShortDescriptionDisplay>{bonusDetailsState.bonusDetails.shortDescription}</ShortDescriptionDisplay>
            <ItemDescription>{t("bonusPage.itemDescription")}</ItemDescription>
            {mapDescription()}
          </BonusPageDetailsContainer>
          <BonusPageFooter>
            {/* <BonusPageFooterInformation>כרגע מסתכלים על הטבה זאת {Math.floor(Math.random() * 10 + 1)}</BonusPageFooterInformation> */}
            <BonusPageButtonContainer>
              <Button varient="secondery" width={131} height={40}>
                <a href={bonusDetailsState.bonusDetails.webSite} target="_blank" style={{ color: "black", textDecoration: "none" }}>
                  {t("bonusPage.toSite")}
                </a>
              </Button>
              {
                auth.authenticated && auth.user ? <Button width={210} height={40} onClick={togglePopup}>
                  {t("bonusPage.use")}
                </Button> :
                  <Button width={210} height={40} onClick={toLoginPage}>
                    {t("bonusRedeem.loginForUse")}
                  </Button>
              }
            </BonusPageButtonContainer>
          </BonusPageFooter>
        </>
      ) : null}

      {bonusDetailsState.error ? <NotFoundMessage varient="h1">{t("bonusPage.notFound")}</NotFoundMessage> : null}
      {isPopUpOpen ? <ConfirmBonus togglePopup={togglePopup} /> : null}
    </BonusPageContainer>
  );
}
