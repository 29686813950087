import React, { useState } from "react";

import { ReactComponent as MenuIcon } from "../../assets/icons/nav-menu.svg";
import { ReactComponent as CloseMenuIcon } from "../../assets/icons/nav-menu-close.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/nav-user.svg";
import { ReactComponent as EVRIcon } from "../../assets/icons/nav-evr.svg";
import ReactCSSTransitionGroup from 'react-transition-group';
import { useAppSelector } from "../../hooks/reduxHooks";
import { SideMenu } from "./OpenNavigation";
import Nav from "./NavBarStyle";
import { useNavigate } from "react-router-dom";
import { UserHeader } from "../userHeader/UserHeader";

const TopNavigation = (props: any) => {
  const auth = useAppSelector((state) => state.auth);

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };
  let navigate = useNavigate();

  return (
    <Nav.TopNav>
      <Nav.TopNavContainer>
        {isSideMenuOpen && <SideMenu toggleMenu={toggleMenu} />}
        <Nav.LogoContainer>
          <EVRIcon
            onClick={() => {
              if (isSideMenuOpen) {
                setIsSideMenuOpen(!isSideMenuOpen);
              }
              navigate("/home");
            }}
          />
        </Nav.LogoContainer>
        <Nav.LogoContainer style={{justifyContent: auth.user ? 'flex-end' : 'space-between'}}>
          {!auth.user ? <UserIcon
            onClick={() => {
              if (auth.user) {
                navigate("/profile");
              } else {
                if (isSideMenuOpen) {
                  toggleMenu();
                }
                navigate("/login");
              }
            }}
          /> : null } 
          {!isSideMenuOpen && <MenuIcon onClick={toggleMenu} />}
          {isSideMenuOpen && <CloseMenuIcon onClick={toggleMenu} />}
        </Nav.LogoContainer>
      </Nav.TopNavContainer>
      {auth.user ? <UserHeader user={auth.user} /> : null}
    </Nav.TopNav>
  );
};

export default TopNavigation;
