import React from "react";
import { FacebookProvider } from "react-facebook";
import LoginForm from "./LoginForm";
import Background from '../../assets/images/bg.png';
import { LoginPageLayout, TitlesContainer } from "./LoginStyles";
import { useTranslation } from "react-i18next";
import { Typography } from "../ui/Typography";

const Login = () => {
  window.React = React;
  const { t } = useTranslation();

  return (
    <LoginPageLayout style={{backgroundImage: `url(${Background})`}}>
      <TitlesContainer>
        <b><Typography varient="h3" style={{color: 'var(--blue)'}}>{t('login.hello')}</Typography></b>
        <Typography varient="body2" style={{color: 'var(--blue)'}}>{t('login.welcome2')}</Typography>
      </TitlesContainer>
      <FacebookProvider appId="233317658099994">
        <LoginForm with2fa={false} />
      </FacebookProvider>
    </LoginPageLayout>
  );
};

export default Login;
