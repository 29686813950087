import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import BonusApi from "../../api/bonus-api";
import { authCheckStart } from "../auth-user/auth-user-slice";
import { loadFailed, loadSuccess, redeemBonus } from "./redeem-bonus-slice";


function* _redeemBonus(action: PayloadAction<{ id: string }>): any {
	const { id } = action.payload;
	const { data, status, error } = yield call(BonusApi.redeemBonus, id);
	try {
	  if (error) {
		yield put(loadFailed(error));
	  }
	  if (status === 200) {
		yield put(loadSuccess(data));
		yield put(authCheckStart());
	  }
	} catch (err: any) {
	  return yield put(loadFailed(err));
	}
  }

  function* bonusDetailsSaga() {
	yield takeLatest(redeemBonus.type, _redeemBonus);
  }
  
  export default bonusDetailsSaga;