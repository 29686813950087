import { createSlice } from '@reduxjs/toolkit';
import { IRedeemBonus } from './../../../interfaces/store/viewing-bonus-interface';

const initialState: IRedeemBonus = {
	transactionToken: null,
	bonusId: null,
	codeCoupon: null,
	email: null,
	error: null,
	loading: false,
};

const _loadSuccess = (state: IRedeemBonus, { payload }: any) => {
	state.loading = false;
	state.error = null;
	state.email = payload.email;
	state.codeCoupon = payload.codeCoupon;
	state.transactionToken = payload.transactionToken;
}

const _loadFailed = (state: IRedeemBonus, { payload }: any) => {
	state.loading = false;
	state.error = payload;
	state.bonusId = null;
}

const _redeemBonus = (state: IRedeemBonus, { payload }: any) => {
	state.loading = true;
	state.error = null;
	state.bonusId = payload.id;
}

const _cleanRedeem = (state: IRedeemBonus) => {
	state.loading = false;
	state.error = null;
	state.bonusId = null;
	state.transactionToken = null;
	state.codeCoupon = null;
	state.email = null
}

const bonusDetailsSlice = createSlice({
	name: "redeemBonus",
	initialState,
	reducers: {
		loadSuccess: _loadSuccess,
		loadFailed: _loadFailed,
		redeemBonus: _redeemBonus,
		cleanRedeem: _cleanRedeem
	},
});

const { actions, reducer } = bonusDetailsSlice;
export const { loadSuccess, loadFailed, redeemBonus, cleanRedeem } = actions;

export default reducer;