import React from "react";
import { useAppSelector } from "../../../hooks/reduxHooks";

export default function TermsOfUse() {
  const auth = useAppSelector((state) => state.auth);
  return (<iframe style={{
    height: `calc(100% - ${auth.authenticated ? '120px' : '85px'})`,
    marginTop: auth.authenticated ? '110px' :'75px',
    width: 'calc(100% - 5px)'
  }} src="https://crossworld-files.s3.eu-central-1.amazonaws.com/Terms.html"/>);
}
