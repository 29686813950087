import styled from "styled-components";
import Spinner from "../ui/Spinner";
import { Typography } from "../ui/Typography";

export const HomeContainer = styled.div`
  margin-top: 60px;
  padding: 16px;
`;
export const NotFoundMessage = styled(Typography)`
  text-align: center;
  margin-top: 50px;
`;
