import React from 'react';
import styled from 'styled-components';

import { initialsByName } from '../../utils/utils';
import { StyledRoundImage } from '../common/styledComponents';

export const RoundNameLogo = styled.div`
	width: ${(props: IProfileRoundImageProps) => props.width + 'px'};
	height: ${(props: IProfileRoundImageProps) => props.height + 'px'};
	background: var(--primary-orange);
	border-radius: ${(props: IProfileRoundImageProps) => (props.radius ? props.radius : (props.width / 2)) + 'px'};
	align-items: center;
	display: flex;
	justify-content: center;
	color: white;
	font-size: ${(props: IProfileRoundImageProps) => props.fontSize ? props.fontSize + 'px' : 16 + 'px'};
	line-height: 16px;
	user-select: none;
	box-sizing: border-box;
`;

const ImageContainer = styled.div`
	height: ${(props: IProfileRoundImageProps) => props.height + 'px'};
	width: ${(props: IProfileRoundImageProps) => props.width + 'px'};
	display:inline-block;
	overflow: hidden;
	border-radius:${(props: IProfileRoundImageProps) => props.radius ? props.radius : (props.width / 2) + 'px'};
	border: none;
	position: relative;
	padding: 0;
;`

interface IProfileRoundImageProps {
	imgUrl?: string,
	fullName: string,
	width: number,
	height: number,
	radius?: number,
	fontSize?: number
};

const ProfileRoundImage = React.memo((props: IProfileRoundImageProps) => {
	let image = props.imgUrl ?
		<ImageContainer {...props}>
			<StyledRoundImage src={props.imgUrl} width={props.width} height={props.height} />
		</ImageContainer>
		:
		<ImageContainer {...props}>
			<RoundNameLogo {...props}>{initialsByName(props.fullName)}</RoundNameLogo>
		</ImageContainer>
		;

	return image;
})


export default ProfileRoundImage;