import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import BonusApi from "../../api/bonus-api";
import {
  fetchByIdFail,
  fetchByIdSuccess,
  getBonusById,
} from "./bonusDetails-slice";

function* _getBonusById(action: PayloadAction<{ bonusId: string }>): any {
  const { bonusId } = action.payload;
  const { data, status, error } = yield call(BonusApi.getBonusById, bonusId);
  try {
    if (error) {
      yield put(fetchByIdFail(error));
    }
    if (status === 200) {
      yield put(fetchByIdSuccess(data));
    }
  } catch (err: any) {
    return yield put(fetchByIdFail(err));
  }
}
function* bonusDetailsSaga() {
  yield takeLatest(getBonusById.type, _getBonusById);
}

export default bonusDetailsSaga;
