import React from "react";
import styled from "styled-components";

import { IFormInputProps } from "../../../interfaces/components/ui/inputs";
import { FlexRow } from "../../common/containers";
import { Typography } from "../Typography";
import Input from "./Input";

const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;
const ErrorMessageContainer = styled(FlexRow)`
`;

const FormInput = (props: IFormInputProps) => {
  const {
    value,
    onChange,
    containerStyles,
    placeholder,
    varient,
    disabled,
    valid,
    withValid,
    Icon,
    onIconClick,
    type,
    errorMessage,
    withShowingError,
    inputContainerStyles,
    errorMessageContainerStyles,
  } = props;
  return (
    <FormInputContainer style={containerStyles}>
      <Input
        value={value}
        onChange={onChange}
        varient={varient || "contained"}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        withValid={withValid}
        valid={valid}
        Icon={Icon}
        onIconClick={onIconClick}
        containerStyles={inputContainerStyles}
      />
      {withShowingError ? (
        <ErrorMessageContainer style={errorMessageContainerStyles}>
          {errorMessage ? (
            <Typography varient='subtitle1' color='error'>
              {errorMessage}
            </Typography>
          ) : null}
        </ErrorMessageContainer>
      ) : null}
    </FormInputContainer>
  );
};

export default FormInput;
