import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import BonusApi from "../../api/bonus-api";
import { fetchFail, loadPage, fetchPageSuccess } from "./bonus-slice";

function* _loadPage(action: PayloadAction<{ page: number; RESULTS_PER_LOAD_LIMIT: number }>): any {
  try {
    const { data, status, error } = yield call(BonusApi.getAllBonuses, action.payload.page, action.payload.RESULTS_PER_LOAD_LIMIT);

    if (error) {
      yield put(fetchFail(error));
    }
    if (status === 200) {
      yield put(fetchPageSuccess(data));
    }
  } catch (err: any) {
    return yield put(fetchFail(err));
  }
}

function* bonusSaga() {
  yield takeLatest(loadPage.type, _loadPage);
}

export default bonusSaga;
