import jwt_decode from "jwt-decode";

import env from '../config';

export interface DecodedData {
    exp: number;
    [key: string]: any;
};
export const getTokenRefreshTime = (token: string): number => {
    const fiveMinutes = 300000;
    const decoded: DecodedData = jwt_decode(token);
    const expiration = new Date(decoded.exp * 1000);
    let tokenTimeLeft = expiration.getTime() - new Date().getTime() - fiveMinutes;
    if (tokenTimeLeft <= 0) {
        return 1;
    }
    return tokenTimeLeft;
};

export const initialsByName = (name: string) => {
    if (!name) {
        return '';
    }
    const names = name ? name.split(' ') : '';
    let initials = names.length === 1 ? names[0].substring(0, 2).toUpperCase() :
        names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const isNumber = (str: string): boolean => {
    if (typeof str !== 'string') {
        return false;
    }

    if (str.trim() === '') {
        return false;
    }

    return !Number.isNaN(Number(str));
}

export const getImageSource = (imagePath: string | null) => {
    if (imagePath) {
        const url = `${env.apiGateway.IMAGE_STORAGE_URL}/${imagePath}`;
        return url;
    } else {
        return null
    }
}