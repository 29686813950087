const auth = {
  userToken: "/api/v1/auth/token",
  verifyCode: "/api/v1/auth/code",
  resendCode: "/api/v1/auth/code/resend",
  userinfo: "/api/v1/auth/userinfo",
  evrUserinfo: "/api/v1/auth/evr/userinfo",
  logout: "/api/v1/auth/logout",
  updatePassword: "/api/v1/auth/updatePassword",
  forgotPassord: "/api/v1/auth/forgot",
};

const files = {
  files: "/api/v1/files",
  presignedUrl: "/api/v1/files/presigned-url",
};

const bonus = {
  getAll: "/api/v1/bonus",
  getWithId: "/api/v1/bonus/",
};

const coupon = {
  redeem: '/api/v1/coupons/redeem'
};

const contactUs = {
  contact: "/api/v1/common/contactUs"
}

const ServerRoutes = {
  auth,
  files,
  bonus,
  contactUs,
  coupon
};

export default ServerRoutes;
