import React from "react";
import {
  BottomContent,
  CompanyLogo,
  CompanyName,
  CardContainer,
  CardBonusImg,
  CardImgContainer,
  BonusPrice,
  BonusCardText,
  PriceText,
  BonusCardTitle,
  DashedBorder,
  CardButtonContiner,
} from "./CardStyle";
import { Button } from "../../ui/Buttons";
import { ReactComponent as GemsIcon } from "../../../assets/icons/diamond-icon-blue.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IBonus } from "../../../interfaces/models/bonus";
import { logEvent } from "@firebase/analytics";
import { analytics } from "../../../config";

export default function Card(card: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cardData = card.card as IBonus;

  const handleClick = () => {
    navigate(`/bonus/${cardData._id}`);
    logEvent(analytics, 'view_item', {
      item_list_id: cardData._id,
      item_list_name: cardData.title,
      timestamp: new Date().getTime()
    });
  };

  return (
    <CardContainer onClick={handleClick}>
      <CardImgContainer>
        <CardBonusImg src={cardData.image} alt='img' />
        <CompanyLogo src={cardData.logo} alt='img' />
      </CardImgContainer>

      <BonusCardTitle varient='mainText'>{cardData.title}</BonusCardTitle>
      <BonusCardText varient='body2'>{cardData.shortDescription}</BonusCardText>
      <DashedBorder />
      <BottomContent>
        <CardButtonContiner>
          <Button
            width={109}
            height={30}
            varient='primary'
            onClick={handleClick}>
            {t("card.button")}
          </Button>
        </CardButtonContiner>

        <BonusPrice>
          {cardData.evrCurrency?.toLocaleLowerCase() === "gems" ? <GemsIcon /> : null}
          <PriceText varient='body2'>
            {cardData.priceEvrCurrency} + {cardData.currency}
            {cardData.price}
          </PriceText>
        </BonusPrice>
      </BottomContent>
    </CardContainer>
  );
}
