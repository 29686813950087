import styled from "styled-components";
import { FlexColumn } from "../../common/containers";

export const ContactsContainer = styled(FlexColumn)`
	margin: 90px 10px;
	padding-bottom: 30px;
	direction: rtl;
`;

export const BackHome = styled.a`
	color: var(--primary-blue);
`;