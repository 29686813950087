import React, { CSSProperties } from "react";
import { SpinnerCircular } from "spinners-react";
import { ReactComponent as InfinitySvg } from "../../assets/icons/infinity-loader.svg";
import { useTheme } from "styled-components";
import styled from "styled-components";
interface ISpinnerProps {
  style?: CSSProperties;
  loading?: boolean;
}
const Spinner = (props: ISpinnerProps) => {
  const theme = useTheme();
  return (
    <SpinnerCircular
      enabled={props.loading}
      style={{ color: theme.palette.primary.main, ...props.style }}
    />
  );
};

const SpinnerStyle = styled.div`
  width: 100px;
  height: 100px;
  border: 5px solid gray;
  border-radius: 100%;
  animation: animate1 2s linear 0s infinite;
  border-top: 5px solid greenyellow;
  margin: auto;
  @keyframes animate1 {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const VanillaSpinner = () => {
  return <SpinnerStyle></SpinnerStyle>;
};

export const InfinityLoader = () => {
  return <InfinitySvg></InfinitySvg>
}

export default Spinner;
