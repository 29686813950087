import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { Button } from "../../ui/Buttons";
import { InfinityLoader } from "../../ui/Spinner";
import {
  CopyText,
  CouponPreview,
  DisclaimerText,
  Popup,
  PopupBtnContainer,
  PopupContainer,
  PopupHeadline,
  PopupImg,
  WarningText,
} from "./confirmBonusStyle";
import { cleanRedeem, redeemBonus } from '../../../store/features/bonusDetails/redeem-bonus-slice';
import { ReactComponent as Present } from "../../../assets/icons/present.svg";
import Stoli from "../../../assets/icons/stoli.png";
import { useNavigate } from "react-router-dom";
import { logEvent } from "@firebase/analytics";
import { analytics } from "../../../config";

interface IPopupProps {
  togglePopup: () => void;
}

export const ConfirmBonus: React.FC<IPopupProps> = ({ togglePopup }) => {
  const auth = useAppSelector((state) => state.auth);
  const bonusDetailsState = useAppSelector((state) => state.bonusDetails);
  const redeemState = useAppSelector((state) => state.redeemBonus);
  const [couponCopied, setCouponCopied] = useState(false);
  // const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const redeemCoupon = () => {
    dispatch(redeemBonus({ id: bonusDetailsState.bonusDetails?._id }));
    logEvent(analytics, 'purchase_coupon', {
      item_list_id: bonusDetailsState.bonusDetails?._id,
      item_list_name: bonusDetailsState.bonusDetails?.title,
      price: bonusDetailsState.bonusDetails?.price,
      gems: bonusDetailsState.bonusDetails?.priceEvrCurrency,
      timestamp: new Date().getTime()
    })
  }

  const closeAndClean = () => {
    dispatch(cleanRedeem());
    togglePopup();
  }

  const copyCoupon = async () => {
    if (redeemState.codeCoupon) {
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(redeemState.codeCoupon);
      } else {
        document.execCommand('copy', true, redeemState.codeCoupon);
      }
      setCouponCopied(true);
      logEvent(analytics, 'copy_coupon_code', {
        item_list_id: bonusDetailsState.bonusDetails?._id,
        item_list_name: bonusDetailsState.bonusDetails?.title,
        price: bonusDetailsState.bonusDetails?.price,
        gems: bonusDetailsState.bonusDetails?.priceEvrCurrency,
        timestamp: new Date().getTime()
      });
    }
  }

  const goToWebSite = () => {
    logEvent(analytics, 'goto_sponsor_site', {
      item_list_id: bonusDetailsState.bonusDetails?._id,
      item_list_name: bonusDetailsState.bonusDetails?.title,
      webSite: bonusDetailsState.bonusDetails?.webSite,
      price: bonusDetailsState.bonusDetails?.price,
      gems: bonusDetailsState.bonusDetails?.priceEvrCurrency,
      timestamp: new Date().getTime()
    });
    window.open(bonusDetailsState.bonusDetails?.webSite, '_blank', 'noreferrer');
  }

  let view = null;
  if (redeemState.transactionToken) {
    view = (
      <Popup>
        <PopupHeadline varient='h4' style={{ direction: 'rtl' }}>
          {t('bonusRedeem.youHaveIt')}
        </PopupHeadline>
        <Present height={'100px'}></Present>
        <DisclaimerText style={{ direction: 'rtl', fontSize: '17px' }}>{t("bonusRedeem.youPurchased")} <b>{bonusDetailsState.bonusDetails?.title}</b></DisclaimerText>
        <DisclaimerText style={{ direction: 'rtl', padding: '10px 0' }}>{t("bonusRedeem.yourCouponIs")}</DisclaimerText>
        <CouponPreview onClick={copyCoupon}>
          {redeemState.codeCoupon}
          <CopyText>{!couponCopied ? t('bonusRedeem.copyCoupon') : t('bonusRedeem.couponCopied')}</CopyText>
        </CouponPreview>
        <DisclaimerText style={{ direction: 'rtl', padding: '5px 0' }}>{t("bonusRedeem.userItBefore")}</DisclaimerText>
        <DisclaimerText style={{ direction: 'rtl', padding: '5px 0' }}>{t("bonusRedeem.sentToMail")}  <b>{redeemState.email}</b></DisclaimerText>
        <PopupBtnContainer>
          <Button varient='secondery' width={131} height={40} onClick={closeAndClean} style={{ color: 'black' }}>
            {t("bonusRedeem.close")}
          </Button>
          <Button width={210} height={40} onClick={goToWebSite} style={{ color: "white" }}>
              {t("bonusRedeem.buyItem")}
          </Button>
        </PopupBtnContainer>
      </Popup>
    )
  }
  else if (redeemState.error) {
    view = (
      <Popup>
        <PopupHeadline varient='h4' style={{ direction: 'rtl' }}>
          {t('bonusRedeem.errorTitle')}
        </PopupHeadline>
        <img src={Stoli} height={'100px'} />
        <WarningText>{t("bonusRedeem.errorMessage")}</WarningText>
        <PopupBtnContainer>
          <Button varient='secondery' width={131} height={40} onClick={closeAndClean} style={{ color: 'black' }}>
            {t("bonusRedeem.close")}
          </Button>
          <Button width={210} height={40} onClick={redeemCoupon}>
            {t("bonusRedeem.tryAgain")}
          </Button>
        </PopupBtnContainer>
      </Popup>
    )
  }
  else {
    view = (
      <Popup>
        <PopupHeadline varient='h4' style={{ direction: 'rtl' }}>
          {bonusDetailsState.bonusDetails?.title}
        </PopupHeadline>
        <PopupImg
          src={bonusDetailsState.bonusDetails?.image}
          alt='img'
        ></PopupImg>
        <DisclaimerText>{t("bonusRedeem.disclaimer")}</DisclaimerText>
        <WarningText>{t("bonusRedeem.warning")}</WarningText>
        {auth.user.gems < bonusDetailsState.bonusDetails?.priceEvrCurrency! ?
          <DisclaimerText style={{ direction: 'rtl', textAlign: 'center', color: 'var(--primary-pink)', fontSize: '16px' }}>{t("bonusRedeem.notEnoughGems")}</DisclaimerText>
          : null}
        <PopupBtnContainer>
          <Button varient='secondery' width={131} height={40} onClick={togglePopup} style={{ color: 'black' }}>
            {t("bonusRedeem.cancel")}
          </Button>
          <Button width={210} height={40} onClick={redeemCoupon} disabled={auth.user.gems < bonusDetailsState.bonusDetails?.priceEvrCurrency!}>
            {t("bonusRedeem.use")}
          </Button>
        </PopupBtnContainer>
      </Popup>
    );
  }

  return (
    <PopupContainer>
      {redeemState.loading ?
        <InfinityLoader />
        :
        view
      }
    </PopupContainer>
  );
};
export default ConfirmBonus;
