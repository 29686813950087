import styled from "styled-components";
import { FlexRowSpaced } from "../common/containers";

export const UserHeaderContainer = styled(FlexRowSpaced)`
  padding: 10px 0px 0px 0px;
  z-index: 3;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--nav-background);
`;
export const GemsContainer = styled.div`
  height: 35px;
  width: 73px;
  z-index: 4;
  border-radius: 4px;
  /* box-shadow: 0px 0px 4px 2px rgba(29, 39, 37, 0.06); */
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-weight: 700;
    color: rgba(220, 86, 158, 1);
  }
`;
